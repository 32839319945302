@import '../../styles/mixins';
.products {
  padding: 15px;

  .heading {
    text-transform: capitalize;
    margin-bottom: 50px;

    @include respond(phone) {
      display: none;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 6rem;

    @include respond(mid-tab) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(phone) {
      grid-template-columns: 1fr;
    }
  }

  h3 {
    font-weight: 400;
    margin-bottom: 20px;
  }
}
