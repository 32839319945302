@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

*,
::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  max-width: 100%;
}
html {
  height: 100%;
  font-size: 62.5%;

  @include respond(phone) {
    font-size: 56%;
  }
}
body {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  line-height: 1.2;
  font-size: 1rem;
  min-height: 95%;
  /*   background: $color-primary; */
  color: rgba(0, 0, 0, 0.788);
  position: relative;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.8rem;
}

p {
  font-size: 2rem;
}
a {
  text-decoration: none;
  font-size: 1.6rem;
}
a:visited {
  color: #000;
}
ul li {
  list-style: none;
}

.container {
  width: 140rem;
  margin: 2rem auto;
  padding: 1rem;

  @include respond(tab-land) {
    width: 120rem;
  }
}
