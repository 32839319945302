@import '../../styles/main';

.cart {
  display: flex;
  flex-direction: column;
  min-height: 600px;

  height: 100%;

  h4 {
    font-weight: 500;

    span {
      font-weight: bold;
    }
  }
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cart-item-image {
  width: 100%;

  margin-right: 10px;
}

.cart-item-details {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 40% 10% 40%;
  gap: 10px;

  /*  &:nth-child(1) {
    width: 150px;
  } */

  h3 {
    font-size: 1.6rem;
    margin-top: 10px;
    text-transform: initial;
  }

  button {
    padding: 5px;
    min-width: 25px;
  }

  h4 {
    font-weight: 300;
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .price {
    font-size: 2rem;
    font-weight: 600;
    margin-top: 20px;
  }

  .quality-controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    button {
      cursor: pointer;
      width: 24px;
      height: 24px;
      background: transparent;
      border: 1px solid #000;
    }

    span {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
}

.attribute {
  width: 100%;
  button {
    min-width: 24px;
    min-height: 24px;
  }

  div > div {
    display: flex;
    flex-wrap: wrap;
  }
}

.place-order-button {
  margin: 2rem 0;
  width: 100%;
  padding: 1.5rem;
  background: $color-secondary;
  font-family: inherit;
  text-transform: uppercase;
  color: #fff;
  border: 0;
  cursor: pointer;
}

.loader-container {
  position: relative;

  > div > div {
    position: absolute;
    /* top: 0; */
    bottom: 24px;
    right: 25px;
    transform: scale(0.8);

    @include respond(phone) {
      bottom: 20px;
    }
  }
}

.total {
  margin: 2.5rem 0;
  display: flex;
  justify-content: space-between;
  margin-top: auto; // Pushes total to the bottom of the cart
  padding: 10px 0;
  font-family: 'Raleway';
  span {
    font-size: 1.8rem;
    font-weight: bold;
  }
}
