.product-details {
  display: flex;
  gap: 10rem;
  padding: 15px;

  @media only screen and (max-width: 1100px) {
    gap: 5rem;
  }

  @media only screen and (max-width: 920px) {
    flex-direction: column;
    gap: 3rem;
  }
}

.product-summary {
  padding: 5px;
  max-width: 330px;
  h4 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  h3 {
    margin-top: 1.5rem;
  }
}

.price {
  margin-top: 1.5rem;
  h3 {
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
  }
}
.value {
  font-weight: 700;
  margin: 15px 0;
  font-size: 2.4rem;
}

.product-description {
  margin-top: 10px;
  min-width: 50px;
  max-width: 356px;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  height: 338px;
  overflow-y: auto;

  font-size: 1.8rem;

  h1,
  h2,
  h3 {
    font-size: 2.5rem;
  }

  p {
    margin: 10px 0;
    font-size: 1.8rem;
  }
}
