.overlay {
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Gray out the background
  z-index: 1000; // High z-index to cover other elements
}

.cart-container {
  border-top: 1px solid rgba(238, 238, 238, 0.39);
  top: 0;
  right: 0;
  background: white;
  padding: 20px;
  width: 345px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1001;
  overflow-y: scroll;
  max-height: 665px;
}
