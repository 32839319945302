@mixin flex-center-column {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// MEDIA QUERY MANAGER

/*

0 - 600PX: phone
600 - 900 Tablet portrait
900 - 1200 Tablet landscape 
{1200 - 1800} is where normal style apply 
1800px + : Big desktop
*/

/*
$breakpoint argument choices:
- phone
- tab-port
- tab-land 
- big-desktop
*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 500px) {
      @content;
    }
  }

  @if $breakpoint == mid-tab {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 900px) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 1200px) {
      @content;
    }
  }
}
