.attributes {
  h3 {
    margin-bottom: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
  }

  &-item {
    display: grid;
    grid-template-columns: repeat(4, min-content);
    gap: 5px;
  }
}
