.cart-icon {
  position: relative;

  span {
    background: black;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: absolute;
    top: -11px;
    left: 10px;
    width: 20px;
    height: 20px;
  }
}
