@import '../../styles/variable';
.product-gallery {
  display: grid;
  grid-template-columns: 130px 1fr;
  gap: 20px;

  @media only screen and (max-width: 720px) {
    grid-template-columns: 1fr;
  }
}

.main-image {
  max-height: 600px;
  max-width: 600px;
  position: relative; /* Needed for absolute positioning of arrows */
  margin: 0 auto;

  img {
    max-height: 600px;
    max-width: 600px;

    @media only screen and (max-width: 1024px) {
      max-height: 500px;
      max-width: 100%;
    }
  }
}

.thumbnails-image {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 600px;
  height: auto;
  overflow-y: scroll;

  @media only screen and (max-width: 1024px) {
    max-height: 500px;
  }

  @media only screen and (max-width: 720px) {
    flex-direction: row;
    height: auto;
    max-width: 400px;
    margin: 0 auto;
  }

  img {
    width: 100px;
    height: 90px;
    object-fit: cover;
    border: 1px solid rgba(238, 238, 238, 0.466);
    padding: 5px;
    cursor: pointer;

    &.selected-image {
      border-color: $color-secondary; // Blue border for the selected image
      border-width: 2px;
      padding: 3px; // Adjust padding to ensure the border does not overlap the image
    }
  }
}

.arrow-left,
.arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  color: white;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-left {
  left: 10px; /* Position the left arrow towards the left edge of the image */
}

.arrow-right {
  right: 10px; /* Position the right arrow towards the right edge of the image */
}

.arrow-left:hover,
.arrow-right:hover {
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Darker background on hover for better visibility */
}
