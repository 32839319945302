@import "../../styles/variable.scss";
.header {
  display: flex;
  display: fixed;
  width: 100%;
  height: 80px;

  @media only screen and (max-width: 1200px) {
    padding: 0 15px;
  }
}

.nav-links {
  display: flex;
  color: #000;

  li:not(:last-child) {
    margin-right: 10px;
  }

  a {
    text-transform: uppercase;
  }
}
