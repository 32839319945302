@import '../../styles/variable';

.add-to-cart-button {
  display: block;
  width: 100%;
  padding: 12px 10px;
  text-transform: uppercase;

  background: $color-secondary;
  color: #fff;
  border: 1px solid $color-secondary;

  margin: 20px 0;
  cursor: pointer;

  &:hover {
    background: transparent;
    color: #000;
  }
}
.disabled {
  background: rgb(224, 224, 224);
  color: rgba(88, 85, 85, 0.596);
  border: none;
  cursor: not-allowed;

  &:hover {
    background: rgb(224, 224, 224);
    color: rgba(88, 85, 85, 0.596);
  }
}
